import { Flex, Link, Text } from '@chakra-ui/react';
import React from 'react';

const Footer = () => {
  return (
    <Flex
      w="full"
      bg="blackAlpha.50"
      minHeight="10vh"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      justifyContent="center"
    >
      <Text mb="3">
        Copyright © 2023 Tenex Software Club - All Rights Reserved.
      </Text>
    </Flex>
  );
};

export default Footer;