import { Box, useDisclosure } from '@chakra-ui/react';
import React, { useRef } from 'react';

import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import DrawerComponent from './components/DrawerComponent';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Nav from './components/Nav';

// import Services from './components/Services';
// import Testimonials from './components/Testimonials';

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  return (
    <Box>
      <Nav ref={btnRef} onOpen={onOpen} />
      <Hero />
      <AboutUs />
      {/* <Services /> */}
      {/* <Testimonials /> */}
      <ContactUs />
      <Footer />

      <DrawerComponent isOpen={isOpen} onClose={onClose} btnRef={btnRef} />
    </Box>
  );
}

export default App;